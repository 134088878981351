const TooltipsData = {
  pages: {
    new: {
      steps: {
				description: {
					slug: {
						message: "Each data stream is assigned a permanent web page which will display details about the data stream. The slug is the url segment following the site hostname. (for your data shop and/or the Data Stream Marketplace)"
					}
				}
      }
    },
    accessRules: {
      constraints: {
        heading: "Sed do eiusmod tempor incididunt ut",
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        linkText: "more info",
        linkHref: "http://www.google.com"
      },
      buyers: {
        heading: "Sed do eiusmod tempor incididunt ut",
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        linkText: "more info",
        linkHref: "http://www.google.com"
      },
      description: {
        heading: "Sed do eiusmod tempor incididunt ut",
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        linkText: "more info",
        linkHref: "http://www.google.com"
      },
      cost: {
        heading: "Sed do eiusmod tempor incididunt ut",
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        linkText: "more info",
        linkHref: "http://www.google.com"
      }
    }
  }
}

export default TooltipsData